
import { defineComponent } from "vue";
import Header from "@/components/announcement/Header.vue";
import List from "@/components/announcement/List.vue";
import ListItem from "@/components/announcement/ListItem.vue";
import {
  ListAnnouncement,
  Announcement,
} from "@/components/announcement/index";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  name: "Announcement",
  components: {
    Header,
    List,
    ListItem,
    InfiniteLoading,
    LoadingIcon,
  },
  data() {
    return {
      infiniteId: +new Date(),
      page: 1,
      announcementList: [] as ListAnnouncement,
      category: "actor",
    };
  },
  watch: {
    category(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$pushGAEvent("announcement_category_click", {
          announcement_category: newValue === "배우" ? "actor" : "staff",
        });
        this.announcementList.splice(0, this.announcementList.length);
        this.page = 1;
        this.infiniteId++;
      }
    },
  },
  computed: {
    categoryLabelKr(): string {
      return this.categoryKr + " " + "공고";
    },
    categoryKr(): string {
      return this.category == "actor" ? "배우" : "스탭";
    },
  },
  methods: {
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.announcementList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.error();
        });
    },
    load() {
      return this.$axios
        .get("/announcement/external", {
          params: {
            page: this.page,
            role: this.categoryKr,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.announcementList.splice(0, this.announcementList.length);
              this.announcementList.push(...res);
            } else {
              this.announcementList.push(...res);
            }
          }
          return result;
        });
    },
    openLink(announcement: Announcement): void {
      if (announcement?.link) {
        this.$pushGAEvent("announcement_view", {
          announcement_category: this.category === "배우" ? "actor" : "staff",
          announcement_url: announcement.link,
          announcement_name: announcement.title,
        });

        this.$axios
          .get(`/announcement/external/${announcement.id}`)
          .then(() => {
            announcement.is_read = true;
            this.$flutter.callHandler("launchUrl", {
              url: announcement.link,
            });
          });
      }
    },
  },
  beforeMount() {
    this.$flutter.callHandler("showGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("hideGnb");
  },
});
