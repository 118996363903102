
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BellOutlineIcon from "icons/BellOutline.vue";
import MagnifyIcon from "icons/Magnify.vue";

export default defineComponent({
  components: { BellOutlineIcon, MagnifyIcon },
  emits: ["goSearch", "openAlert", "update:modelValue"],
  data() {
    const store = useStore();
    return {
      store,
      picked: "actor",
      unreadCount: 0,
    };
  },
  computed: {
    isLogin: function () {
      return this.store.getters["member/isLogin"];
    },
  },
  methods: {
    change(e: InputEvent) {
      this.picked = (e.target as HTMLInputElement).value;
      this.$emit("update:modelValue", this.picked);
    },
    clickAlert() {
      this.$pushGAEvent("alert_click");
      this.$emit("openAlert");
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$axios.get("/push/alert/unread/count").then((res) => {
        this.unreadCount = res.data.count;
      });
    }
  },
});
